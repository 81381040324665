<template>
    <div class="layout-header-section d-lg-flex d-block justify-content-between">
        <div class="header-label col-lg-6 col-12">UAN Data</div>
    </div>
    <div class="search-voter-section-outer" style="height: calc(100vh - 141px);">
        <div class="layout-content-section">
            <div class="form-box-outer">
                <div class="row">
                    <div class="col-lg-3 col-md-3 col-12">
                        <div class="custom-form-group">
                            <label for="formuancardinput" class="form-label">UAN No.
                                <span class="text-danger">*</span></label>
                            <input type="text" v-model="uancardnumber" class="form-control text-uppercase"
                                id="formuancardinput" @keypress="onlyNumber" :disabled="inputdisabled" placeholder="Enter UAN No."
                                autocomplete="off" maxlength="12" />
                            <span class="custom-error" v-if="erruan">
                                {{ erruan }}
                            </span>
                            <span class="custom-error" v-if="errmsg">
                                {{ errmsg }}
                            </span>
                        </div>
                    </div>
                    <div class="
                            col-lg-4 col-md-4 col-12
                          " v-if="!sendotpbtnhide">
                        <div class="custom-form-group" style="margin-top: 26px;">
                            <button type="button" class="header-btn-outer btn btn-primary"
                                @click="getuancarddetails(uancardnumber)" :disabled="errStatus || uancardnumber.length == 0
                                    " :style="{ width: '104px' }">
                                <span v-if="!showloader">Get Details</span>
                                <div class="
                                  spinner-border
                                  text-light
                                  custom-spinner-loader-btn
                                " role="status" v-if="showloader">
                                    <span class="visually-hidden">Loading...</span>
                                </div>
                            </button>
                        </div>
                    </div>
                    <div class="col-lg-5 col-md-5 col-12 text-end" v-if="clearbtnflag">
                        <div class="custom-form-group" style="margin-top: 26px;">
                            <button type="button" class="btn custom-view-detail-btn" @click="searchvoterclearbtn()">Clear
                                All
                            </button>
                        </div>
                    </div>
                </div>
                <div class="search-tab-outer-scroll" :style="{ height: '339px' }" v-if="mobiledetailshowflag">
                    <div class="voter-exit-box-outer mb-3" v-if="UANTabDetails.data.employment_history.length != 0">
                        <div class="row">
                            <div class="col-lg-12 col-md-12 col-12">
                                <div class="custom-form-group">
                                    <label class="individual-header-label">Employment History</label>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12 col-12">
                                <div class="row"
                                    v-for="(emphistory, index) in UANTabDetails.data.employment_history"
                                    :key="emphistory"
                                    v-bind:class="{ dividerrowhide: index == UANTabDetails.data.employment_history.length - 1 }">
                                    <div class="col-lg-4 col-md-4 col-12">
                                        <div class="customer-label-group mb-2">
                                            <label class="form-label">Date of Joining</label>
                                            <div class="from-label-value text-capitalize">
                                                {{ format_date(emphistory.date_of_joining) }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-4 col-12">
                                        <div class="customer-label-group mb-2">
                                            <label class="form-label">Date of Exit</label>
                                            <div class="from-label-value text-capitalize">
                                                {{ format_date(emphistory.date_of_exit) }}
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div class="col-lg-4 col-md-4 col-12">
                                        <div class="customer-label-group mb-2">
                                            <label class="form-label">Employer Name</label>
                                            <div class="from-label-value text-capitalize">
                                                {{ emphistory.establishment_name ? emphistory.establishment_name : "N/A" }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-4 col-12">
                                        <div class="customer-label-group mb-2">
                                            <label class="form-label">Guardian Name</label>
                                            <div class="from-label-value text-capitalize">
                                                {{ emphistory.guardian_name ? emphistory.guardian_name : "N/A" }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-4 col-12">
                                        <div class="customer-label-group mb-2">
                                            <label class="form-label">Member Id</label>
                                            <div class="from-label-value text-capitalize">
                                                {{ emphistory.member_id ? emphistory.member_id : "N/A" }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-4 col-12">
                                        <div class="customer-label-group mb-2">
                                            <label class="form-label">Name</label>
                                            <div class="from-label-value text-capitalize">
                                                {{ emphistory.name ? emphistory.name : "N/A" }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="voter-exit-box-outer pb-3" v-if="nodatafoundflag">
                    <div>No records found.</div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import ApiService from "../../service/ApiService";
import useValidate from "@vuelidate/core";
import moment from 'moment';
export default {
    data() {
        return {
            v$: useValidate(),
            uancardnumber: '',
            erruan: '',
            errmsg: '',
            errStatus: false,
            showloader: false,
            UANTabDetails: "",
            mobiledetailshowflag: false,
            inputdisabled: false,
            sendotpbtnhide: false,
            nodatafoundflag: false,
            clearbtnflag: false,
        };
    },
    validations() {
        return {
        };
    },
    ApiService: null,
    created() {
        this.ApiService = new ApiService();
    },
    watch: {
        uancardnumber(uancardnumber) {
            this.errmsg = "";
            this.validateuanCardNumber(uancardnumber);
            if (uancardnumber.length == 0) {
                this.erruan = "";
                this.errStatus = false;
            }
        },
    },
    mounted() {
    },
    methods: {
        getuancarddetails(e) {
            this.showloader = true;
            this.ApiService.getUANHistoryDetails({ uan_number: e }).then((data) => {
                if (data.status == true) {
                    this.UANTabDetails = JSON.parse(data.data.uan_details_response);
                    this.mobiledetailshowflag = true;
                    this.showloader = false;
                    this.inputdisabled = true;
                    this.sendotpbtnhide = true;
                    this.clearbtnflag = true;
                } else {
                    this.mobiledetailshowflag = false;
                    this.showloader = false;
                    this.nodatafoundflag = true;
                    this.clearbtnflag = true;
                }
            });
        },
        validateuanCardNumber(val) {
            this.errStatus = false;
            if (!this.validUAN(val)) {
                this.erruan = "Please enter valid UAN number";
                this.errStatus = true;
            }
            if (this.errStatus == false) {
                this.erruan = "";
                return true;
            }
        },

        validUAN: function (e) {
            var re = /([0-9]){12}$/;
            return re.test(e);
        },
        onlyNumber($event) {
            let keyCode = $event.keyCode ? $event.keyCode : $event.which;
            if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
                $event.preventDefault();
            }
        },
        format_date(value) {
            if (value) {
                return moment(String(value)).format("DD/MM/YYYY");
            } else {
                return 'N/A';
            }
        },
        searchvoterclearbtn() {
            this.uancardnumber = '';
            this.erruan = '';
            this.errmsg = '';
            this.errStatus = false;
            this.showloader = false;
            this.UANTabDetails = '';
            this.mobiledetailshowflag = false;
            this.inputdisabled = false;
            this.sendotpbtnhide = false;
            this.nodatafoundflag = false;
            this.clearbtnflag = false;
        },
    },
};
</script>
<style scoped>
.form-box-outer {
    padding: 16px 16px;
    margin-bottom: 15px;
    border: 1px solid #e0e5ed;
    border-radius: 0px 0px 4px 4px;
}

.search-voter-section-outer {
    display: block;
    overflow-y: auto;
    overflow-x: hidden;
    height: 512px;
}

.search-voter-section-outer::-webkit-scrollbar {
    display: block;
}

.search-voter-section-outer::-webkit-scrollbar {
    width: 0.3em;
    height: 0.3em;
}

.search-voter-section-outer::-webkit-scrollbar-thumb {
    background-color: rgb(113 115 119 / 49%);
    border-radius: 3px;
}

.search-tab-outer-scroll {
    position: relative;
    overflow-y: auto;
    overflow-x: hidden;
}

.search-tab-outer-scroll::-webkit-scrollbar {
    display: block;
}

.search-tab-outer-scroll::-webkit-scrollbar {
    width: 0.3em;
    height: 0.3em;
}

.search-tab-outer-scroll::-webkit-scrollbar-thumb {
    background-color: rgb(113 115 119 / 49%);
    border-radius: 3px;
}

.voter-exit-box-outer {
    padding: 16px 16px 0;
    background: #e4f8f0 0% 0% no-repeat padding-box;
    border: 1px solid #bbd9cc;
}

.customer-label-group {
    margin-bottom: 20px;
}

.customer-label-group .form-label {
    font-family: "AcuminPro-Regular";
    font-size: 13px;
    letter-spacing: 0px;
    color: #4a5463;
    line-height: 13px;
    margin-bottom: 6px;
}

.customer-label-group .from-label-value {
    font-family: "AcuminPro-SemiBold";
    font-size: 14px;
    letter-spacing: 0.28px;
    color: #2b2e38;
    line-height: 20px;
}
.dividerrowhide .p-divider {
    display: none;
}
.individual-header-label {
    font-family: 'AcuminPro-SemiBold';
    font-size: 13px;
    text-align: left;
    letter-spacing: 0.52px;
    color: #0d488b;
    line-height: 15px;
}
</style>